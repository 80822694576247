
.spinnner-col .input-group .form-control {
    text-align: center;
    height: 40px;

}

.order-description {
    height: 120px;
}

.basket-item-bottom-row {
    height: 3px;
}

.basket-items-header{
    font-family: "IBSBold";
    padding: 20px;
}

.basket-item-row-mobile {
    border-left: lightgrey 1px solid;
    border-right: lightgrey 1px solid;

    height: 60px;
    padding: 4px;
    background-color: lightgrey;
}

.basket-item-row-mobile strong {
    border-left: lightgrey 1px solid;
    border-right: lightgrey 1px solid;

    font-family: "IBSBold";
}

.basket-item-bottom-row-mobile {
    height: 10px;
    border-bottom: lightgrey 1px solid;
}

.basket-item-top-row-mobile {
    height: 10px;
    border-bottom: lightgrey 1px solid;
}

.basket-item-footer-correction {
    height: 5rem;
}

.order-description-right {
    padding-right: 0;
    padding-left: 0;
}

.order-description-left {
    padding-left: 0;

}