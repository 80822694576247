@font-face {
    font-family: 'IBSRegular';

    src: local('IBSFontRegular'), url(./fonts/Raleway-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'IBSBold';

    src: local('IBSFontBold'), url(./fonts/Raleway-Bold.ttf) format('truetype');
}

.home-welcome {
    font-family: 'IBSBold';
}


/*src: local('IBSFontRegular'), url(./fonts/Raleway-Regular.ttf) format('truetype');*/
/*src: local('IBSFontExtraBold'), url(./fonts/Raleway-ExtraBold.ttf) format('truetype');*/
/*src: local('IBSFontBoldItalic'), url(./fonts/Raleway-BoldItalic.ttf) format('truetype');*/
/*src: local('IBSFontBlack'), url(./fonts/Raleway-Black.ttf) format('truetype');*/
/*src: local('IBSFontBlackItalic'), url(./fonts/Raleway-BlackItalic.ttf) format('truetype');*/
/**/
/*src: local('IBSFontExtraBoldItalic'), url(./fonts/Raleway-ExtraBoldItalic.ttf) format('truetype');*/
/*src: local('IBSFontExtraLight'), url(./fonts/Raleway-ExtraLight.ttf) format('truetype');*/
/*src: local('IBSFontExtraLightItalic'), url(./fonts/Raleway-ExtraLightItalic.ttf) format('truetype');*/
/*src: local('IBSFontItalic'), url(./fonts/Raleway-Italic.ttf) format('truetype');*/
/*src: local('IBSFontLight'), url(./fonts/Raleway-Light.ttf) format('truetype');*/
/*src: local('IBSFontLightItalic'), url(./fonts/Raleway-LightItalic.ttf) format('truetype');*/
/*src: local('IBSFontMedium'), url(./fonts/Raleway-Medium.ttf) format('truetype');*/
/*src: local('IBSFontMediumItalic'), url(./fonts/Raleway-MediumItalic.ttf) format('truetype');*/
/*src: local('IBSFontSemiBold'), url(./fonts/Raleway-SemiBold.ttf) format('truetype');*/
/*src: local('IBSFontSemiBoldItalic'), url(./fonts/Raleway-SemiBoldItalic.ttf) format('truetype');*/
/*src: local('IBSFontThin'), url(./fonts/Raleway-Thin.ttf) format('truetype');*/
/*src: local('IBSFontThinItalic'), url(./fonts/Raleway-ThinItalic.ttf) format('truetype');*/


.active-link {
    color: red !important;
}

html,
body {
    height: 100%;
    font-family: "IBSRegular";
    font-size: 1rem;
    /*min-height:100vh;*/
    background-color: #f9f9f9;
}

.lower-container{
    margin-top: 80px;
}

.btn-primary {
    color: #fff;
    background-color: #007bff;
    border-color: red;
}

body {
    margin: 0;
    padding: 0;
}


@media (max-width: 767px) {
    .desktop-only {
        display: none;
    }
}

.row-bottom-spacer{
    margin-bottom: 200px;
    margin-bottom: 200px;
}
@media (min-width: 768px) {
    .mobile-only {
        display: none;
    }
}

.errmsg {
    color: red;
}

.bg-dark {
    background-color: black !important;
}

.btn{
    border-radius: 30px;
    font-family: "IBSBold";
}

.ibs-bold{
    font-family: "IBSBold";
}

.list-items .list-row:nth-of-type(odd) {
    background-color: #efefef;
}

.list-items .list-row:nth-of-type(even) {
    background-color: #efefef;
}

.list-items .list-row-print:nth-of-type(odd) {
    background-color: #efefef;
}

.list-items .list-row-print:nth-of-type(even) {
    background-color: #efefef;
}

.list-row {
    min-height: 55px;
    padding: 12px
}
.list-row-print {
    min-height: 10px;
}

.item-image {
    max-height: 40px;
    max-width: 100px;
    justify-content: center;
}

.row {
    display: flex;
    flex-wrap: wrap;
     margin-right: 0!important;
     margin-left: 0!important;
}

.pass_error {
    color: red
}

@media (min-width: 1200px) {
    .container, .container-sm, .container-md, .container-lg, .container-xl {
        max-width: 1300px;
    }
}

.container, .container-fluid, .container-sm, .container-md, .container-lg, .container-xl {
    width: 100%;
    padding-right: 0px!important;
     padding-left: 0px!important;
    margin-right: auto;
    margin-left: auto;
}

.flex-fill {
   flex:1 1 auto;
}

.col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12{
    padding-left: 3px!important;
    padding-right: 3px!important;
}
