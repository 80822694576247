Nav{
    font-family: "IBSBold";
}

.navbar-dark{
    color: white;
    min-height: 64px;
}

.navbar-dark .navbar-nav .nav-link {
    color: white;
}

.nav-dropdown{
    margin-right: 20px;
}

.brand-img{
    max-height: 25px
}

.menu-basket-button{
    min-width: 200px;
}

.navbar-dark .navbar-nav .nav-link:hover {
    color: red;
}

.navbar-expand-lg .navbar-nav .nav-link {
    /* padding-right: 0.5rem; */
    padding-left: 1rem;
}

.nav-link:focus{
    color: red!important;
}

.menu-item{
    margin-right: 10px;
}

.navbar .container{
    width: 100%;
    padding-left: 0!important;
    padding-right: 0!important;
    margin-right: auto;
    margin-left: auto;
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: 0!important;
        padding-left: 1rem;
    }
}



