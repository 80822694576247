.season-card{
    width: 80%;
    cursor: pointer;
    margin-bottom: 30px;
    font-family: "IBSBold";

    /*padding-left: 0!important;*/
    /*padding-right: 0!important;*/


}

.card-title{
    font-size: 14px!important;
    text-align: center;
}

.productGroupContainer {
    display: inline-block;
}


InputSpinner {
    max-width: 100px;
}

.cart {
    max-width: 25px;
}

.cartspinner {
    max-width: 40px;
}

table td {
    padding: 0.5rem !important;
}

.itemImage {
    width: 40px;
}

.search {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

@media (min-width: 992px) {

.search-col1{
    padding-left: 0;
}

.search-col3{
    padding-right: 0;
}

}




