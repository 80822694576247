.order-header{
    font-family: "IBSBold";
    font-size: 14px;
    border-left: white solid 10px;
    padding: 5px;
}
.order {
    cursor: pointer;
    border-top: black solid 1px;
    border-bottom: black solid 1px;
    border-right: black solid 1px;
    border-left: #04a6d7 solid 10px;
    padding: 5px;

}



.order-expanded {
    cursor: pointer;
    /*border-top: black solid 1px;*/

    border-right: black solid 1px;
    border-left: #04a6d7 solid 10px;
    padding: 5px;
    border-bottom: lightgrey dashed 1px;
    background-color: #e7e7e7;

}

.order-expanded-description {
    cursor: pointer;
    /*border-top: black solid 1px;*/

    border-right: black solid 1px;
    border-left: #d6006f solid 10px;
    padding: 5px;
    border-bottom: lightgrey dashed 1px;
    background-color: #e7e7e7;

}



.order:hover {
    cursor: pointer;
    border-top: black solid 1px;
    background-color: #e7e7e7;
}


.order-bottom {
    height: 8px;
}

.order-item-header {
    border-left: yellow solid 10px;
    border-right: black solid 1px;
    border-bottom: black solid 1px;
    background-color: lightgrey;
    font-family: "IBSBold";

    height: 60px;
}

.order-item-bottom-row {
    height: 3px;
}

.order-item-bottom-row-mobile{
    height: 3px;
    border-bottom: lightgrey 1px solid;
}



.date-custom-width{
    margin-right: -10px;
}

.date-custom-width.col-md-1{
    min-width: 130px;
}


.order-item {
    min-height: 60px;
    border-left: #fddd04 solid 10px;
    border-right: black solid 1px;
}

.order-item-print {
    min-height: 20px;
    border-left: #fddd04 solid 10px;
    border-right: black solid 1px;
}


@media all {
  .page-break {
    display: none;
  }
}

@media print {
  html, body {
    height: initial !important;
    overflow: initial !important;
    -webkit-print-color-adjust: exact;
  }
}

@media print {
  .page-break {

    display: block;
    page-break-before: auto;
  }
}

@page {
  size: auto;
  margin: 20mm;
}

.order-item-image {
    max-height: 40px;
    border-left: yellow solid 10px;
    border-right: black solid 1px;

}

.order-item-mobile {
        margin-right: 0!important;
margin-left: 0!important;

    min-height: 60px;
    border-left: yellow solid 10px;
    border-right: black solid 1px;
}

.order-print-button {
    padding: 1px;
}