InputSpinner {
    max-width: 100px;
}

.cart {
    max-width: 25px;
}

.cartspinner {
    max-width: 40px;
}

.add-to-card-img {
    max-height: 20px;
    margin-left: -5px;
}

.product-item-mobile-row-top{
    min-height:20px;
    background-color: lightgrey;
    font-family: "IBSBold";
}

.product-item-mobile-row{
    background-color: lightgrey;
    min-height:40px;

}

.btn .badge {
    position: relative;
    top: -1px;
    margin-left: 5px;
    margin-right: -8px
}

.product-item-mobile-row strong{
    background-color: lightgrey;
    min-height:50px;
    font-family: "IBSBold";
}

.product-item-mobile-row-image{
    background-color: lightgrey;
    min-height:70px;
}

.product-item-mobile-row-bottom{
    background-color: lightgrey;
    min-height:10px;}

.input-group-prepend .btn, .input-group-append .btn {
    position: relative;
    z-index: -1 !important;
    height: 40px;
}

.pagination-col{
    padding-right: 0
}


.input-group-prepend .btn, .input-group-append .btn {
    position: relative;
    z-index: 0 !important;
}

.page-item.active .page-link {
    z-index: 0 !important;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}

.page-item.active .page-link {
    z-index: 0 !important;
    color: #fff;
    background-color: #007bff;
    border-color: #007bff;
}



.productitems-header {
    /*font-size: larger;*/
    font-family: "IBSBold";
    padding: 20px
}
.productitems-header-col {
    /*font-size: larger;*/
    font-family: "IBSBold";
}

.react-numeric-input {


}

.basket-group{
    text-align: right;
}

.basket-button{
    display: inline-block;
    min-width: 90px;
}
.in-basket{
    display: inline-block;
    min-width: 50px;
}


.mobile-product-item {
    margin-left: 5px;
    margin-right: 5px;
    font-size: 14px;
}

.spinnner-col .input-group .form-control {
    text-align: center;
}

.item-bottom-row {
    height: 3px;
}


/*pagination*/
.pagination {
    margin-bottom: 1rem;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    list-style-type: none;
}

li a {
    border-radius: 7px;
    padding: 0.1rem 1rem;
    border: gray 1px solid;
    cursor: pointer;
}

li.previous a,
li.next a,
li.break a {
    border-color: transparent;
}

li.active a {
    background-color: #0366d6;
    border-color: transparent;
    color: white;
    min-width: 32px;
}

li.disabled a {
    color: grey;
}

li.disable,
li.disabled a {
    cursor: default;
}

