.footer {
    background-color: green;
    border-top: 2px solid red;
    position: fixed;
    width: 100%;
    bottom: 0;
    color: white;
    font-size: 0.9rem;
}

.footer-right {
    padding-right: 0;

}

.footer a {
    color: white;
}

.footer .row{
        display: flex;
    flex-wrap: wrap;
    height: 4rem;
}


.footer-left {
    padding-left: 0;
}

.footer a {
    margin: 0;
    padding: 0;
    border: none;
}